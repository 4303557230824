.mobile-navigation
{
    display: none; 
}
@media only screen and (min-width: 320px) and (max-width: 991px) {
    .main-header-menu
    {
        display: none;
    }
    .logo-sec
    {
        position: relative;
        z-index: 2;
        a
        {
            display: block;
            img 
            {
                max-width: 96px;
            }
        }
    }

    .nav-item img
    {
        filter: none!important;
    }
    .mobile-navigation {
        display: flex !important;
        padding: 15px;
        -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
        background-color:rgba(255,255,255,0.99);
        justify-content: space-between;
        position: fixed;
        top:0;
        width: 100%;
        z-index: 5;
    }
    .dsarrow {
        &::after {
            display: block;
            content: "\f078";
            font-family: "Font Awesome 5 free";
            font-style: normal;
            font-weight: 900;
            text-decoration: inherit;
            color: #fff;
        }
        position: absolute;
        right: 0;
        top: 0;
        padding: 23px 23px 23px 83px;
        cursor: pointer;
    }
    .screen {
        .menu-user-name
        {
            position: absolute;
            top:22px;
            right:70px;
            width: calc(100% - 200px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: right;
        }
        nav.mobile-nav {
            ul {
                li {
                    position: relative;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
                    .sub-menu {
                        padding: 0 0 0 40px;
                        background: #f2eaea;
                    }
                    a {
                        display: block;
                        -webkit-transition: all 0.3s ease;
                        -moz-transition: all 0.3s ease;
                        -o-transition: all 0.3s ease;
                        transition: all 0.3s ease;
                        color: $font-color!important;
                    }
                }
                .btn-li 
                {
                    display: flex;
                    padding: 15px 0 0 15px;
                    justify-content: start;
                    a 
                    {
                        color: #fff!important;
                        padding: 4px 20px 0px 20px;
                        text-align: center;
                        &:first-child
                        {
                            margin-right: 15px;
                        }
                    }
                }
                padding: 30px 0 0 0;
            }
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            height: 0;
            width: 100%;
            padding: 2em;
            -webkit-opacity: 0;
            -moz-opacity: 0;
            opacity: 0;
            -webkit-transform: scale(0.85);
            -ms-transform: scale(0.85);
            transform: scale(0.85);
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            transition: all 0.3s ease;
            position: fixed;
            overflow-x: scroll;
            background: rgba(255, 255, 255, 1);
        }
        position: relative;
        -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        nav.mobile-nav.toggled {
            display: block;
            z-index: 10;
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            transform: scale(1);
            -webkit-opacity: 1;
            -moz-opacity: 1;
            opacity: 1;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            transition: all 0.3s ease;
            height: 100vh;
            align-items: flex-start;
        }

        a.target-burger.toggled {
            position: fixed;
            right: 15px;
            ul.buns {
                li.bun {
                    -webkit-transform: rotate(45deg) translateZ(0);
                    transform: rotate(45deg) translateZ(0);
                    &:last-child {
                        -webkit-transform: rotate(-45deg) translateZ(0);
                        transform: rotate(-45deg) translateZ(0);
                        position: relative;
                        top: -1px;
                    }
                }
            }
        }
        a.target-burger {
            width: 3.25em;
            height: auto;
            position: relative;             
            display: block;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            transition: all 0.3s ease;
            z-index: 99;
            cursor: pointer;
            &:hover {
                cursor: pointer;
                -webkit-opacity: 1;
                -moz-opacity: 1;
                opacity: 1;
            }
            ul.buns {
                width: 1.625em;
                height: 1.625em;
                list-style: none;
                padding: 0;
                position: absolute;
                top:16px;
                right: 0;
                left: 0;
                margin: auto;
                -webkit-transition: -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1), color 1s cubic-bezier(0.23, 1, 0.32, 1);
                transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1), color 1s cubic-bezier(0.23, 1, 0.32, 1);
                -webkit-transform: translateZ(0);
                transform: translateZ(0);
                color: #fff;
                li.bun {
                    width: 100%;
                    height: 2px;
                    background-color: $secondary-color;
                    top: 50%;
                    margin-top: -0.75px;
                    -webkit-transform: translateY(-3.75px) translateZ(0);
                    transform: translateY(-3.75px) translateZ(0);
                    -webkit-transition: -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1), background-color 1s cubic-bezier(0.23, 1, 0.32, 1);
                    transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1), background-color 1s cubic-bezier(0.23, 1, 0.32, 1);
                    &:last-child {
                        -webkit-transform: translateY(3.75px) translateZ(0);
                        transform: translateY(3.75px) translateZ(0);
                    }
                }
            }
        }
    } 
    .master-header .nav-item .nav-link{padding: 10px 15px;}
    .master-header .nav-item .nav-link .tooltip .tooltip-inner{padding-top:0;padding-bottom: 0;}
    .master-header .nav-item .btn-custom{margin: 15px auto 0 auto;color: #fff!important;max-width: 250px;border:none!important;}
}