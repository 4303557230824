$theme-colour: #3196E3;
$colour-white: #ffffff;
$off-blue: #EEF8FF;
$light-gray: rgba(0,0,0, 0.10);


body{
	font-family: 'Roboto', sans-serif;
	font-weight:  400;   
	background: #f9fbfd;
	color: #444;
	position: relative;
}

.formApp
{
	position: fixed;
	height: 100vh;
	width: 100%;
	&::before
	{
		position: absolute;
		content: '';
		margin:0;
		z-index: -1;
		background-repeat: no-repeat;
		background-image: url(../images/bg-top.svg);
		background-position: top left;
		background-size: auto 100%;
		height: 90%;
		width: 100%;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
	&::after
	{
		position: absolute;
		content: '';
		margin:0;
		z-index: -1;
		background-repeat: no-repeat;
		background-image: url(../images/bg-bottom.svg);
		background-position: bottom right; 
		height: 90%;
		width: 100%;
		bottom: 0;
		right: 0;
		left: auto;
		top: auto;
	}
}
.page-container {
	min-height: 100vh;
	padding-left: 280px;
	transition: all 0.2s ease;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	-ms-transition: all 0.2s ease;
	overflow-y: scroll;
}




.form-wizard {
	position: relative;
	z-index: 0; 	
	.container {
		height: 100vh;
		max-width: 1170px;
		.row.gutter-5 {
			margin: -5px 0;

			> {
				[class*=" col-"], [class*="col-"] {
					padding: 0 10px;
				}
			}
		}
	}
	.wizard {
		padding:30px 0;
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.txt-box {
		margin-bottom: 40px;
	}
	.title {
		margin: 15px 0;
		font-size: 34px;
		line-height: 44px;
		font-weight: 500;
	}
	.sub-title {
		color: rgba(149, 152, 154, 1);
		font-size: 20px;
		line-height: 30px;
		font-weight: 400;
		margin: 15px 0;
	}
	.main-text {
		font-size: 16px;
		margin: 0 0 5px 0;
		font-weight: 500;
		color: #212121;

	}
	.sub-text {
		font-size: 14px;
		padding: 0;
		color: #777f88;
	}   
	.btn.focus, .btn:focus
	{
		box-shadow: none;
	}
	.btn-skip
	{
		border: 1px solid #ddd;
		background-color: $colour-white;
	}
	.custom-click {
		width: 100%;
		height: 100%;
		padding:15px 46px 25px 46px;
		background-color: $colour-white;
		color: #666;
		-webkit-transition: all .2s;
		-o-transition: all .2s;
		transition: all .2s;
		position: relative;
		box-shadow: 2px 2px 5px #0000000D;
		border: 2px solid #E5E5E5;
		border-radius: 6px;
		&:hover {
			transform: scale(1.1);
			outline: 0;
			box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
			border:2px solid $theme-colour;
			/*h5 {
			color: $colour-white;
		}
			.sub-text {
			color: $colour-white; 
		}
			img 
			{
			-webkit-filter: brightness(0) invert(1);
			filter: brightness(0) invert(1);
		}
			i 
			{
			color: $colour-white!important;
		}*/

		}

		.check_corner {
			position: absolute;
			right: 10px;
			top: 2px;
			font-size: 16px;

			i{
				color: #e5e5e5;
			}

		}
	}
	.custom-click.active {
		outline: 0;
		box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
		border:2px solid $theme-colour;
		background-color: #eef8ff;
		/*h5 {
		color: $colour-white;
	}
		.sub-text {
		color: $colour-white; 
	}
		img 
		{
		-webkit-filter: brightness(0) invert(1);
		filter: brightness(0) invert(1);
	}*/

		.check_corner {
			i{
				color: #44cb92;
			}
		}


	}
	.service-list {
		.custom-click {
			.check_corner {
				top: 15px;
			}

		}
	} 
	.img-container {
		img {
			display: inline-block;
			max-width: 100%;
			max-height: 100%;
			vertical-align: middle;
			height: 60px;
		}
	}
	.btn-toggle {
		padding: 12px 40px;
		border-radius: 6px; 
	}
	.form-sec {
		margin: 15px 0 0 0;
		label {
			font-weight: 500;
			color: #666;
			font-size: 14px;
		}
	}
	.dentist-services {
		h5 {
			font-size: 14px;
		}
		.service-list {
			margin: 0 0 20px 0;
		}
		.content-container {
			h5 {
				display: inline-block;
				width: auto;
				text-transform: capitalize;
			}
			i {
				width: 20px;
				display: inline-block;
				color: #ddd;
			}
			.tip {
				margin: 4px 0 0 4px;
				width: auto;
			}         
		}
		.custom-click {
			padding: 15px;
		}

	}
	.available-time {
		.service-list {
			margin: 0 0 10px 0;
		}
		.content-container {
			h5 {
				display: inline-block;
				width: auto; 
				text-transform: capitalize;
			}
			i {
				width: 20px;
				display: inline-block;
				color: #ddd;
			}
			.tip {
				margin: 4px 0 0 4px;
				width: auto;
			}
		}
		.custom-click {
			padding: 15px;
		}

	}
	.goals {
		h5 {
			font-size: 15px;
		}
	}
	.last-visited
	{
		
		h5 
		{
			color:$theme-colour;
			font-size: 24px; 
		}
		.sub-text
		{
			font-weight: 700;
			color: #444;
		}
	}
	.anxiety
	{
		h5
		{
			font-size: 18px;
		}
	}
	.last-visited.anxiety .list-box  , .wizard.preferance .list-box
	{
		max-width: 20%;
		.custom-click
		{
			padding: 30px 15px;
		}
	}
	
	.preferance
	{
		h5
		{
			padding: 0 5px;
			color:$theme-colour;
		}        
	}
	.insurance-providers
	{   
		.custom
		{
			padding:30px 5px;
			min-height: 132px;
			img 
			{
				height: auto;
				width: 100%;
				max-width: 80%;
			}   
		}
		.custom-other
		{
			min-height:132px;
			padding: 19px 15px 29px 15px;
		}
		select 
		{
			width: 100%;
			border: 1px solid #ccc;
			background-color:#ffffff;
			transition: all .3s;
			box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
			padding: 12px 16px;
			border-radius: 4px;
			height: 50px;
		}
	}
	.list-box {
		margin: 0 0 20px 0;
	}
	.unactiveBtn {
		cursor: not-allowed;
		color: $colour-white;
		background-color: #ddd;
		border-color: #ddd;
		opacity: 0.65;
	}
	.selected-none {
		cursor: pointer;
		color: #444;
		border: 1px solid #ddd;
		background-color: $colour-white;
	}
	.activeBtn {
		cursor: pointer;
		color: $colour-white;
		background-color: $theme-colour;
		opacity: 1;
		border:none;
	}
	input {
		border: 1px solid #ccc;
		width: 100%;
		background-color: $colour-white;
		transition: all .3s;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
		padding: 12px 16px;
		border-radius: 4px;
		height: 50px;
		&:focus {
			border-color: transparent;
			-webkit-box-shadow: 0 0 0 2px $theme-colour;
			box-shadow: 0 0 0 2px $theme-colour;
			outline: 0;
		}
	}
	textarea {
		border: 1px solid #ccc;
		width: 100%;
		background-color: $colour-white;
		transition: all .3s;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
		padding: 12px 16px;
		border-radius: 4px;
		resize: none;
		height: 120px;
		&:focus {
			border-color: transparent;
			-webkit-box-shadow: 0 0 0 2px $theme-colour;
			box-shadow: 0 0 0 2px $theme-colour;
			outline: 0;
		}
	}
	.form-title {
		font-size: 14px;
		font-weight: 700;
		margin: 0 0 7px 0;
		color: #666;
	}
	.img-box {
		width: 60px;
		height: 60px;
		margin: auto auto 10px auto;
	}
	.btn-sec {
		.btn {
			margin: 0 5px;
		}
	}
	.booking-reason {
		margin: 0 0 10px 0;
		.custom-click {
			padding: 15px;
		}
	}
	.not-ready {
		.btn-toggle {
			margin: 40px 0 15px 0;
		}
	}
	.issue-box {
		margin: 30px 0 10px 0;
	}
	.info-sec {
		.media {
			background-color: #f3f1f3;
			border: 1px solid #ddd;
			border-radius: 3px;
			padding: 15px;
			margin: 15px 0 0 0;
			i {
				color: #666;
				display: inline-block;
				margin-right: 10px;
				position: relative;
				top: 3px;
			}
			p {
				font-size: 14px;
				color: #666;
				font-weight: 600;
				margin: 0;
				a {
					color: #666;
					text-decoration: underline;
					&:focus {
						color: $colour-white;
						text-decoration: underline;
					}
					&:active {
						color: $colour-white;
						text-decoration: underline;
					}
					&:hover {
						color: #00a2b1;
					}
				}
				strong {
					font-weight: 900;
				}
			}
		}
		.agree {
			i {
				font-size: 18px;
			}
		}
		.stop-help {
			i {
				font-size: 15px;
			}
		}
	}
	.tooltip-button {
		background-color: red !important;
		position: absolute !important;
		z-index: 99 !important;
	}
}

.bottom_bar{     
	padding: 20px 0;
	.btn {
		margin: 0 15px;
		font-size: 18px;
		font-weight: 500;
		background-repeat: no-repeat;
		text-align: left;
		text-transform: capitalize;
	}
	.btn-next
	{
		background-color: #2E88CD;
		color: #fff;        
		background-image: url(../images/next.svg);
		background-position: calc(100% - 25px) center; 
		border: 1px solid #2E88CD;
		padding: 15px 60px 15px 30px;
	} 
	.btn-back
	{
		background-color: #fff;
		color: #4A494A;
		border: 1px solid rgba(233, 233, 233, 1);        
		background-image: url(../images/back.svg);
		background-position: 25px center;
		padding: 15px 30px 15px 60px;
	}

}

.prog_parent {
	text-align: center;
	display: block;
	padding: 0px 20px 20px 20px;
	span 
	{
		font-size: 14px;
	}
}
.gift-cards
{
	padding: 20px 38px 0px 38px;
	width: 100%;
	max-width: 1280px;
	overflow: hidden;
	p 
	{
		text-align: center;
		font-size: 14px;
	}
	.giftcard-slider
	{
		.card-sec
		{

		}
		.slick-list {

			overflow: visible;
		}
	}
}
.quote-sec
{
	background-color: #F9FBFD;
	border-top: 1px solid rgba(0,0,0,0.05);
	border-right: 1px solid rgba(0,0,0,0.05);    
	padding:30px 25px 25px 25px ;
	position: fixed;
	bottom: 0;
	width: 100%;
	max-width: 280px;
	.symbol
	{
		position: absolute;
		top: -25px;
		left: 0;
		right: 0;
		margin: 0 auto;
		font-size: 0;
		height: 50px;
		width: 50px;
		border: 1px solid rgba(0,0,0,0.05);
		border-radius: 50%;
		text-align: center;
		background-color: #fff;
		line-height: 50px;
		z-index: 20;
	}
	.quote
	{
		span 
		{
			font-size: 16px;
			color: #333333;
		}
	}
}
#svg {

	circle {
		stroke-dashoffset: 0;
		transition: stroke-dashoffset 1s linear;
		stroke: $light-gray;
		stroke-width: 3px;
	}

}
#svg #bar {
	stroke:$theme-colour;
	stroke-width: 5px;

}
#cont {
	display: block; 
	border-radius: 100%;
	position: relative;
}
#cnum {
	position: absolute;
	display: block;
	font-size: 30px; 
	font-weight: 700;
	top: 50%; right: 50%;
	transform: translate(50%,-50%);
	sup { 
		font-size: 14px;
		top: -10px;
		left: 5px;
		font-weight: 400;
	}


}


.mb-30
{
	margin-bottom: 30px;
}

.wiz_logo {
	padding:15px 30px;
	img{
		max-width: 100px;
		margin:0 auto;
		display: block; 
	} 
}
.side-nav 
{ 
	width: 280px;
	height: 100vh;
	z-index: 1000; 
	position: fixed;
	overflow: hidden;
	transition: all 0.2s ease;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	-ms-transition: all 0.2s ease;
	.side-nav-inner 
	{
		position: relative;
		height: 100%;
		.ps__rail-x
		{
			display: none!important;
		}
	}
}

/*
* Container style
*/
.ps {
	overflow: hidden !important;
	overflow-anchor: none;
	-ms-overflow-style: none;
	touch-action: auto;
	-ms-touch-action: auto;
}

/*
* Scrollbar rail styles
*/
.ps__rail-x {
	display: none;
	opacity: 0;
	transition: background-color .2s linear, opacity .2s linear;
	-webkit-transition: background-color .2s linear, opacity .2s linear;
	height: 15px;
	/* there must be 'bottom' or 'top' for ps__rail-x */
	bottom: 0px;
	/* please don't change 'position' */
	position: absolute;
}

.ps__rail-y {
	display: none;
	opacity: 0;
	transition: background-color .2s linear, opacity .2s linear;
	-webkit-transition: background-color .2s linear, opacity .2s linear;
	width: 15px;
	/* there must be 'right' or 'left' for ps__rail-y */
	right: 0;
	/* please don't change 'position' */
	position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
	display: block;
	background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
	opacity: 0.6;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
	background-color: #eee;
	opacity: 0.9;
}

/*
* Scrollbar thumb styles
*/
.ps__thumb-x {
	background-color: #aaa;
	border-radius: 6px;
	transition: background-color .2s linear, height .2s ease-in-out;
	-webkit-transition: background-color .2s linear, height .2s ease-in-out;
	height: 6px;
	/* there must be 'bottom' for ps__thumb-x */
	bottom: 2px;
	/* please don't change 'position' */
	position: absolute;
}

.ps__thumb-y {
	background-color: #aaa;
	border-radius: 6px;
	transition: background-color .2s linear, width .2s ease-in-out;
	-webkit-transition: background-color .2s linear, width .2s ease-in-out;
	width: 6px;
	/* there must be 'right' for ps__thumb-y */
	right: 2px;
	/* please don't change 'position' */
	position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
	background-color: #999;
	height: 11px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
	background-color: #999;
	width: 11px;
}

/* MS supports */
@supports (-ms-overflow-style: none) {
	.ps {
		overflow: auto !important;
	}
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.ps {
		overflow: auto !important;
	}
}

.side-nav
{

	.sub-title
	{
		color: rgba(119, 127, 136, 1);
		font-size: 12px;
		line-height: 20px;
		font-weight: 400;
	}  
	.main-title
	{
		color: $font-color;
		font-size: 14px;
		line-height: 22px;
		font-weight: 500;
	}
	.bar-sec
	{
		position: relative;
		height: calc(100vh - 150px);
		padding: 60px 0;
	}
	.text-sec
	{
		padding: 15px 0;
		text-align: center;
	}
	.progress
	{
		margin: 0;
		height: 10px;
		border-radius: 5px;
		overflow: visible;
		background-color: rgba(46, 136, 205,0.3);
		width: 10px;
		height: 100%;
		margin: auto;
		.progress-bar
		{
			position: relative;
			border-radius: 5px;
			z-index: 0;
			background-color: $primary-color;
			width: 100%;
			overflow: visible;
			&::after
			{
				position: absolute;
				content: '';
				height: 20px;
				width: 20px;
				border-radius: 50%;
				background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
				border: 2px solid rgba(46, 136, 205, 1);
				top:auto;
				bottom: -5px;
				left: -5px;
				right: 0;
				margin: 0;
				z-index: 1;
			}
		}
	}
}





@media only screen and (min-width: 320px) and (max-width: 767px) { 

	.formApp {
		position: relative;
		height: auto;
		width: 100%;
		.page-container {
			padding: 0 30px;
			overflow-y: inherit;
		}
		.form-wizard {
			.title {
				font-size: 24px;
				line-height: 34px;
			}
			.sub-title {
				font-size: 16px;
				line-height: 24px;
			}
			.custom-click {
			}
			.bottom_bar {
				display: block !important;
				.btn-back {
					margin: 0 0 10px 0;
					width: 100%;
					text-align: center;
				}
				.btn-next {
					margin: 0 0 10px 0;
					width: 100%;
					text-align: center;
				}
			}
			 

		}
	}

	.side-nav {
		position: relative;
		height: auto;
		width: 100%;
		.bar-sec {
			display: none;
		}
	}

	.form-wizard .last-visited.anxiety .list-box, 
	.form-wizard .wizard.preferance .list-box {
		width: 100%;
		max-width: 100%;
	}
	
	
}


