@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

body {
    font-family: 'Roboto', sans-serif;
}

.main-banner {
    height: 300px;
    position: relative;
    z-index: 0;
    overflow: hidden;
}

.main-banner::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 90px;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: -1;
}

.main-banner img {
    object-fit: cover;
    height: 300px;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
}

.main-banner h1 {
    margin-bottom: 45px;
    color: #fff;
    font-weight: 700;
}

.main-grid-sec h3 {
    font-size: 24px;
    font-weight: 500;
    color: #0A0A0A;
}

.main-grid-sec p {
    color: #0A0A0A;
    margin-bottom: 30px;
}

.main-grid-sec {
    margin: 40px 0;
}

.main-grid-sec .practice-profile {
    margin: 0 0 20px 0;
}

.main-grid-sec .practice-profile .img-box {
    height: 130px;
    width: 130px;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .05);
    border: 1px solid #e9e9e9;
    padding: 15px;
    text-align: center;
}

#tab-about,
#tab-photos,
#tab-services,
#tab-features,
#tab-providers {
    margin: 10px 0 20px 0;
    padding: 10px 0 0 0;
}

.main-grid-sec .tab-view-practice .text-primary {
    color: #e62b4a !important;
}

.main-grid-sec .tab-view-practice h3 {
    position: relative;
    display: inline-block;
    margin-bottom: 15px;

}

.main-grid-sec .tab-view-practice h3::after {
    position: absolute;
    content: '';
    background-color: #0A0A0A;
    height: 1px;
    width: 30px;
    right: -40px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    display: none;
}

.main-grid-sec .tab-view-practice .practice-pics .img-box {
    width: calc((100% / 4) - 10px);
    height: 150px;
    overflow: hidden;
}

.main-grid-sec .tab-view-practice ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.main-grid-sec .tab-view-practice ul li {
    width: calc((100% / 3) - 6px);
    font-size: 14px;
    margin-bottom: 10px;
}

.main-grid-sec .tab-view-practice .practice-pics .img-box img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.main-grid-sec .practice-profile .content-box .p-name {
    font-size: 32px;
    font-weight: 700;
    line-height: 35px;
}

.main-grid-sec .practice-profile .content-box .trusted-txt {
    font-size: 16px;
    color: #0267D4;
    font-weight: 400;
}

.main-grid-sec .practice-profile .content-box .p-address {
    color: #4A494A;
}

.main-grid-sec .practice-sidebar {
    margin: 0 0 40px 0;
}
@media (max-width: 767.98px) {
    .main-grid-sec .practice-sidebar {
        margin: 0 0 10px 0;
    }
}

.main-grid-sec .practice-sidebar .book-sec,
.main-grid-sec .practice-sidebar .authentication-box
{
    background-color: #f9f9f9;
    border: 1px solid #e9e9e9;
}
.main-grid-sec .practice-sidebar .authentication-box div
{
    color: #707070;
}
.main-grid-sec .practice-sidebar .authentication-box .list-group .list-group-item.active{
   background-color: #0A0A0A;
   border-color: #0A0A0A;
}
.main-grid-sec .practice-sidebar .authentication-box .list-group .list-group-item.active .patient-name-txt
{
    color: #fff;
}
.main-grid-sec .practice-sidebar .book-sec h2 {
    font-size: 28px;
    font-weight: 700;
}

.main-grid-sec .practice-sidebar .btn-dark,
.practice-list-desc .btn-dark {
    background-color: #0A0A0A;
    border-color: #0A0A0A;
}

.main-grid-sec .practice-sidebar .btn-primary,
.practice-list-desc .btn-primary {
    background-color: #0267D4;
    border-color: #0267D4;
}

.w-4 {
    width: calc((100%/4) - 10px);
    margin: 0 5px;
}

.availability-box {
    padding: 15px 30px;
}
@media (max-width: 767.98px) { 
    .availability-box {
        padding: 15px 10px;
    } 
}
.availability-box a {
    margin-bottom: 10px;
    color: #0267D4;
    text-decoration: none;
}
.availability-box a.disabled { 
    cursor: default;
    text-decoration: line-through;
}

.availability-box a.active { 
    background-color: #4a494a !important; 
}

.confirm-text {
    border: 1px solid #0267D4;
    background-color: #E5F1FF; 
    border-radius: 20px; 
    max-width: 90%;
    margin: 20px auto;
}

.confirm-text .confirm-text-item {
    padding: 20px;
    border-bottom: 1px solid #C9E0FA;
    text-align: center;
}
.confirm-text .confirm-text-item:last-child {
    border-bottom: none;
}
.confirm-text .confirm-text-item .confirm-text-item-title{
    color: #0267D4 !important;
    text-align: center;
}


.main-grid-sec .practice-sidebar .slots {
    max-height: 215px;
    overflow-y: auto;
}

.flexy-selection .select-slot {
    position: relative;
    border-radius: 40px;
}

.flexy-selection .select-day {
    position: relative;
}

.flexy-selection .select-slot input,
.flexy-selection .select-day input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.flexy-selection .select-slot label {
    background-color: #EAEAEA;
    color: #4A494A;
    text-align: center;
    padding: 2px 6px 6px 6px;
    border-radius: 20px;
}

.flexy-selection .select-day label {
    background-color: #EAEAEA;
    color: #4A494A;
    text-align: center;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    line-height: 42px;
}

.flexy-selection .select-slot input:checked+label,
.flexy-selection .select-day input:checked+label {
    background-color: #0A0A0A;
    color: #fff;
    border-color: #0A0A0A !important;
}



.highlights .highlight-box {
    background-color: #F3F3F3;
    width: 200px;
    margin: 0 10px 10px 0;
    line-height: 1.2;
}
.highlights .highlight-box .img-box
{
    height: 35px;
    width: 35px;
}
.highlights .highlight-box .img-box img 
{
    width: auto;
    height: 100%;
}
.highlights .highlight-box .enq-txt {
    font-size: 12px;
}
.highlights .highlight-box .enq-txt strong 
{
    font-size: 14px;
    display: block;
}
.highlights .highlight-box small {
    font-weight: 600;
}
.highlights .highlight-box img 
{
    max-width: 40px;
    height: auto;
}
.new-footer {
    color: #95989A;
}

.practice-list-desc {
    border: 1px solid #E9E9E9;
    box-shadow: 0px 3px 10px #0000000D;
}

.practice-list-desc .img-box {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    overflow: hidden;
}

.practice-list-desc .img-box img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.practice-list-desc h4 {
    font-size: 20px;
    font-weight: 700;
}

.review-box-nw .img-box {
    height: 50px;
    width: 50px;
    min-width: 50px;
    border-radius: 50%;
    overflow: hidden;
}

.review-box-nw .img-box img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.review-box-nw .rate-stars {
    color: #FF8C00;
    font-size: 12px;
}

.tab-strip a {
    /* color: #0A0A0A; */
    text-decoration: none;
}

.tab-strip a.active {
    font-weight: 600;
}

.review-detail-box {
    box-shadow: 0px 3px 10px #0000000D;
    border: 1px solid #E9E9E9;
    background-color: #fff;
}

.review-detail-box span.no-of-review {
    color: #95989A;
    font-size: 14px;
}

.review-detail-box .static-txt {
    font-size: 11px;
    color: #4A494A;
}

.review-detail-box .rate-stars {
    color: #FF8C00;
    font-size: 12px;
}

.review-detail-box .avg-rating {
    font-size: 10px;
    color: #95989A;
}

.review-detail-box .avg-rating strong {
    font-size: 20px;
    color: #4A494A;
}

/* Provider Profile  */
.provider-profile-grid .btn-secondary {
    background-color: #0267D4;
    border-color: #0267D4;
}

.provider-profile-grid.main-grid-sec .practice-profile .content-box .trusted-txt {
    color: #0267D4;
}
.provider-profile-grid .highlights .highlight-box
{
    width: auto;
}
@media only screen and (max-width: 1199px) {
    .highlights .highlight-box {
        width: 250px;
    }

    .main-grid-sec .tab-view-practice ul li {
        width: calc((100% / 2) - 6px);
    }
}

@media only screen and (max-width: 991px) {
    .main-banner h1 {
        font-size: 32px;
    }

    .main-grid-sec h3 {
        font-size: 20px;
    }

    .flexy-selection .select-day label {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
    }

    .availability-box a {
        font-size: 12px;
    }

    .highlights .highlight-box {
        width: 180px;
    }

    .main-grid-sec .tab-view-practice .practice-pics .img-box {
        height: 90px;
    }

    .practice-list-desc {
        flex-wrap: wrap;
    }

    .practice-list-desc>div {
        width: 100%;
    }

    .practice-list-desc>div:last-child {
        justify-content: center;
        margin-top: 15px;
    }

    .review-detail-box {
        width: 200px;
    }
    .review-detail-box .rate-stars
    {
        font-size: 10px;
    }
    .review-detail-box span.no-of-review
    {
        font-size: 11px;
    }
}

@media only screen and (max-width: 767px) {
    .main-banner {
        height: 250px;
    }

    .main-banner h1 {
        font-size: 28px;
        margin-bottom: 15px;
    }

    .main-grid-sec .practice-sidebar .book-sec h2 {
        font-size: 24px;
    }

    .main-grid-sec .practice-profile .content-box .p-name {
        font-size: 20px;
    }

    .main-grid-sec .practice-profile .img-box {
        height: 100px;
        width: 100px;
    }

    .main-grid-sec .practice-profile .content-box .trusted-txt,
    .main-grid-sec .practice-profile .content-box .p-address {
        font-size: 12px;
    }

    .main-grid-sec .tab-view-practice ul li {
        width: 100%;
    }

    .highlights .highlight-box {
        width: 100%;
    }
    .review-detail-box span.no-of-review
    {
        font-size: 10px;
    }
}

.cursor-pointer {
  cursor: pointer;
}