.availability-tabs {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	&-items { 
		display: flex;
		flex: 1 1 0;
		text-align: center;
		border-bottom: 1px solid #C4C4C4;
		&-item {
			flex: 1 1 0;
			border-bottom: 3px solid transparent;
			padding: 12px 0;
			font-size: 16px;
			line-height: 18px;
			font-weight: 400;
			cursor: pointer;
			&.active {
				color: #0267D4;
				font-weight: 500;
				border-bottom: 3px solid #0267D4;
			}
		}
	}	
	&-content {
		background-color: #fff;
		padding: 30px;
		border: 1px solid #E9E9E9;
		border-radius: 4px;
	}
}
.first-available {
	padding: 0;
	&-items { 
		padding: 30px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		max-height: 275px;
		overflow-y: auto;
		&-item {
			&__title {
				font-weight: 500;
				margin-bottom: 10px;
				position: sticky;
				top: -30px;
				background-color: #fff;
			}
			&__slot {
				display: grid;
				gap: 10px;
				grid-template-columns: repeat(4, 1fr);
				&-item { 
					font-size: 13px;
					background-color: #0267D4;
					color: #fff;
					border-radius: 4px;
					padding: 7px 5px;
					text-align: center;
					cursor: pointer;
					&.active {
						background-color: #4a494a;
						color: #fff;
						font-weight: 500;
						pointer-events: none;
					}
				}
			}
		}
	}
}

.all-available {
	display: flex;
	flex-direction: column;
	gap: 30px;
	.date-selector {
		align-self: center;
		margin: -15px 0;
		input {
			color: #0267D4;
			border: none; 
			padding: 0;
			font-weight: 500;
		}
	}
	.availability-box {
		padding: 0;
		.slots {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			gap: 10px;
			a {
				padding: 7px 5px !important;
				&.available  { 
					background-color: #0267D4;
					color: #fff;   
					cursor: pointer;
				}
			}
		}
		.days-title  {
			align-items: center;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			gap: 10px;
			position: relative;
			max-width: 96%;
			.date-prev, 
			.date-next {
				position: absolute;
				margin: 0;
				cursor: pointer;
			}
			.date-prev  {
				left: -10px;
			}
			.date-next  {
				right: -10px;
			}
		}
	}
}
.practice-sidebar {
	position: sticky;
	top: 40px;
	&.with-header {
		top: 100px;
	}
}

@media (max-width: 767px) {
	.availability-tabs-content {
		padding: 20px 10px;
	}
	.first-available-items-item__slot-item {
		font-size: 12px;
	}
	.all-available .availability-box .days-title  { 
		max-width: 100%;
	}

	.first-available-items {
		padding: 0;
	}
	.all-available .availability-box .days-title .date-next {
		right: -5px;
	  }
	  .all-available .availability-box .days-title .date-prev {
		left: -5px;
	  } 
}