/* --- Banner Section Start --- */
.banner-section {
    background-image: url(../images/banner-bg.svg);
    background-size: cover;
    background-position: center;
    background-color: $primary-color;
    z-index: 0;
    position: relative;
    padding: 0 0;
    height: 100vh;

    .img-section {
        margin: 90px 0 0 0;
    }

    .txt-section {
        margin-top: 70px;

        h1 {
            font-size: 48px;
            line-height: 58px;
            color: #fff;
            font-weight: 300;

            strong {
                font-weight: 700 !important;
            }
        }

        p {
            color: #fff;
            font-size: 22px;
            line-height: 30px;
        }

        .location-title {
            color: #fff;
            line-height: normal;
            font-size: 14px;
            display: block;
            margin: 15px 0;

            img {
                width: 16px;
                display: inline-block;
                margin: -2px 5px 0 0;
            }
        }

        .address-section {
            position: relative;
            z-index: 0;
            width: 45%;
            margin: auto;

            .location-input {
                width: 95%;
                border-radius: 10px;
                padding: 16px 70px 16px 16px;
                border-color: transparent;
                color: $font-color;

                &::-webkit-input-placeholder {
                    /* Edge */
                    color: $font-color;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer */
                    color: $font-color;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &::placeholder {
                    color: $font-color;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .location-btn {
                width: 76px;
                height: 76px;
                border: none;
                border-radius: 50%;
                background-color: $secondary-color;
                display: block;
                text-align: center;
                line-height: 76px;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto 0;

                img {
                    height: 28px;
                    width: auto;
                }
            }
        }
    }
}

/* --- Banner Section End --- */
/* --- Featues Section Start --- */
.feature-section {
    h2 {
        padding: 0 0 0 60px;
        position: relative;
        width: 90%;

        &::before {
            background-color: $font-color;
            width: 5px;
            height: 80%;
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            top: 0;
            margin: auto 0;
        }
    }

    .feature-list {
        padding: 0;
        list-style: none;
        margin: 30px 0 0 0;

        li {
            padding: 0 0 30px 60px;
            background-image: url(../images/list-icon.svg);
            background-size: 20px;
            background-position: 15px 4px;
            background-repeat: no-repeat;
            width: 90%;

            .list-title {
                line-height: 26px;
                font-size: 20px;
                color: $primary-color;
                font-weight: 500;
                padding: 0 0 7px 0;
            }

            .list-desc {
                line-height: 26px;
                font-size: 16px;
                color: $font-color;
                font-weight: 400;
            }
        }
    }

    .client-review-icon {
        border-radius: 50%;
        background-color: $secondary-color;
        box-shadow: 0px 10px 15px 0px rgba(37, 90, 150, 0.1);
        position: absolute;
        width: 136px;
        height: 136px;
        z-index: 1;
        padding: 50px 0 0 0;
        bottom: 0;
        right: -20px;
        text-align: center;

        img {
            height: 36px;
            width: auto;
        }
    }

    .client-review-img {
        position: relative;
        text-align: right;
    }
}

/* --- Featues Section End --- */
/* --- Icon Section Start --- */
.feature-icon {
    padding: 0px 0 30px 0;

    .feature-icon-section {
        background-color: $font-color;
        text-align: center;
        padding: 30px 60px;
        border: none;
        border-radius: 10px;
        margin: 0;

        .f-img {
            height: 80px;
            margin: 0 0 15px 0;
            position: relative;
            z-index: 0;

            img {
                height: 80px;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                margin: auto;
            }

            .user-card {
                height: 56px;
            }

            .time {
                height: 54px;
            }
        }

        .f-title {
            font-size: 14px;
            line-height: 20px;
            font-weight: 700;
            color: #fff;
            text-transform: uppercase;
        }
    }
}

/* --- Icon Section End --- */
/* --- Provider Section Start --- */
.provider-section {
    padding: 60px 0 30px 0;

    .provider-box {

        &:nth-child(2),
        &:nth-child(7) {
            margin: 0 0 0 -30px;
        }

        &:nth-child(3) {
            margin: 0 15px;
        }

        &:nth-child(5) {
            margin: 0 -30px 0 0;
        }
    }

    .provider-name {
        background-color: #fff;
        -webkit-box-shadow: 0px 10px 15px 0px rgba(37, 90, 150, 0.1);
        -moz-box-shadow: 0px 10px 15px 0px rgba(37, 90, 150, 0.1);
        box-shadow: 0px 10px 15px 0px rgba(37, 90, 150, 0.1);
        text-align: center;
        padding: 15px;
        height: 100%;
        min-height: 100px;
        display: flex;
        flex-wrap: wrap;
        img{
            max-width: 100%;
        }
    }

    .txt-box {
        background-color: $secondary-color;
        color: #fff;
        margin: 0 -45px;
        padding: 22px 15px;

        .que-txt {
            font-size: 16px;
            margin: auto;
            line-height: normal;
        }

        .ans-txt {
            font-size: 16px;
            margin: auto;
            line-height: normal;
        }
    }
}

/* --- Provider Section End --- */
/* --- Location Section Start --- */
.locaction-section {
    background-image: url(../images/location-bg.png);
    background-position: center center;
    background-repeat: no-repeat;

    .city-list {
        background-color: $secondary-color;
        padding: 15px 30px 15px 55px;
        color: #fff;
        margin: 15px 0;
        display: block;
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
        position: relative;
        -moz-transition: all 300ms ease-in-out 0s;
        -o-transition: all 300ms ease-in-out 0s;
        -webkit-transition: all 300ms ease-in-out 0s;
        transition: all 300ms ease-in-out 0s;
        -webkit-box-shadow: -300px 0px 0px 0px $primary-color inset;
        -moz-box-shadow: -300px 0px 0px 0px $primary-color inset;
        box-shadow: -300px 0px 0px 0px $primary-color inset;

        &::before {
            content: '';
            background-image: url(../images/location.svg);
            ;
            position: absolute;
            background-size: 18px;
            background-repeat: no-repeat;
            width: 50%;
            height: 100%;
            left: 20px;
            margin: auto 0;
            top: -4px;
            bottom: 0;
            background-position: left center;
            -moz-transition: all 300ms ease-in-out 0s;
            -o-transition: all 300ms ease-in-out 0s;
            -webkit-transition: all 300ms ease-in-out 0s;
            transition: all 300ms ease-in-out 0s;
        }

        &:hover {
            -webkit-box-shadow: 0px 0px 0px 0px $primary-color inset;
            -moz-box-shadow: 0px 0px 0px 0px $primary-color inset;
            box-shadow: 0px 0px 0px 0px $primary-color inset;
            padding-left: 50px;

            &::before {
                background-size: 20px;
            }
        }

    }

    .btn-custom {
        margin: 30px 0 0 0;
    }
}

/* --- Location Section End --- */
/* --- Testimonial Start --- */
.testimonials {
    position: relative;
    z-index: 0;

    .bg-img {
        //background-color: #F2F9FF;
        height: 100%;
        width: 100%;
        @include overlay();
        content: 'aa';
        z-index: -1;
        border-bottom-style: solid;
        border-bottom-color: #F2F9FF;
        border-right-style: solid;
        border-right-color: transparent;
    }

    .rattings {
        padding: 60px 0 0 0;

        .rateing_star {
            margin: 0 0 10px 0
        }

        ;

        p {
            font-weight: 700;
            margin: 0;
        }
    }

    .right_coma {
        left: -60px;
        position: absolute;
        padding: 46px;
        border-radius: 50%;
        background-color: #e62b4a;
        height: 150px;
        width: 150px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        color: #fff;

        img {
            width: 100%;
        }
    }

    .testi_img {
        border-radius: 10px;
        width: 100%;
    }

    .slick-prev.slick-arrow,
    .slick-next.slick-arrow {
        display: none !important;
    }

    .side_section {
        margin: 0 0 0 calc(-100% + 83.333333%) !important;

        .carousel_slide {
            background-color: #2E88CD;
            border-radius: 10px;
            padding: 60px;
            position: relative;

            .bg_coma {
                position: absolute;
                right: 0px;
                bottom: 0px;
                opacity: 0.2;
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                transform: rotate(180deg);
            }

            p {
                color: #fff;
                font-size: 22px;
                line-height: 32px;
                margin: 0 0 60px 0;
            }

            h3 {
                font-size: 28px;
                color: #fff;
                font-weight: 700;
                line-height: 28px;
                margin: 0;

                span {
                    display: block;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 34px;
                }
            }
        }
    }

    .slick-dots {
        text-align: center;
        margin: 0 auto;
        position: absolute;
        bottom: -120px;
        left: 0;
        right: 0;

        li {
            position: relative;
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            padding: 0;
            cursor: pointer;

            button {
                width: 12px;
                height: 12px;
                padding: 5px;
                cursor: pointer;
                background-color: #d6d8db;
                color: transparent;
                border: 0;
                outline: none;
                border-radius: 50%;
            }

            &.slick-active button {
                background-color: #e62b4a;
                opacity: 0.5;
            }
        }
    }
}

/* --- Testimonial end --- */
/* --- Dentist dashboard Section Start --- */
.dentist_dashboard {
    background-color: #2E88CD;
    position: relative;

    .bg_design {
        position: absolute;
        top: -60px;
        left: -20px;
    }

    .dentist_pt {
        h2 {
            color: #fff;
            text-align: left;
            margin-bottom: 30px;
        }

        p {
            font-size: 18px;
            line-height: 26px;
            font-weight: 300;
            color: #fff;
        }

        ul.big_list {
            margin-top: 50px;
            padding: 0;
            list-style: none;

            li {
                background: url(../images/border-list-icon.svg) no-repeat; 
                background-size: 60px; 
                padding: 0px 0 5px 40px; 
                background-position:top left; 
                width: 85%; 
                font-size: 16px; 
                font-weight: 400; 
                line-height: 26px; 
                color: #fff; 
                margin: 30px 0;
                background-size: 24px; 


                &:first-child {
                    margin-top: 0px;
                }

                p {
                    font-size: 16px;
                    font-weight: 400;

                    strong {
                        font-weight: 600;
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .dashboard {
        position: relative;
        margin: 15px 0 0 20px;

        p {
            font-size: 18px;
            font-weight: 700;
            color: #fff;
            line-height: 28px;
        }
    }

    .next_patient {
        background-color: #16cf50;
        padding: 25px 32px;
        width: auto;
        border-radius: 10px;
        position: absolute;
        right: -80px;
        bottom: -50px;

        .bell {
            border-bottom: 1px solid #fff;
            padding-bottom: 10px;

            img {
                display: inline-block;
                width: auto;
                position: relative;
                top: -14px;
            }

            p {
                display: inline-block;
                font-size: 18px;
                line-height: 28px;
                color: #fff;
                font-weight: 700;
                padding-left: 10px;
                margin: 0px;

                span {
                    display: block;
                    font-size: 15px;
                    font-weight: 400;
                }
            }
        }

        .contact {
            padding-top: 20px;

            p {
                display: inline-block;
                font-size: 18px;
                line-height: 28px;
                color: #fff;
                font-weight: 700;
                padding-left: 10px;
                margin: 0;
            }
        }
    }
}

/* --- Dentist dashboard Section End --- */


/* Download CTA */



.app-cta {
    padding-bottom: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .1);

    .app-btn {
        display: inline-block;
        height: 80px;
        width: auto;
        margin: 7px 20px 7px 0;

        img {
            width: auto;
            height: 100%;
        }
    }
}