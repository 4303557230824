/** --- Switch on off --- **/
$gray: lightgray;
$blue: #007bff;
$height: 26px;
$width: $height * 2;
//$transition-time: 0.3s;

.switch input[type=checkbox]{
    height: 0;
    width: 0;
    display: none;
}

.switch label {
    cursor: pointer;
    width: $width;
    height: $height;
    background: $gray;
    display: block;
    border-radius: $height / 4;
    position: relative;
    margin: 0;
}

.switch label:before {
    content: attr(data-off);
    position: absolute;
    top: 0;
    right: 0;
    font-size: 10px;
    padding: 0 ($height / 4);
    color: white;
    line-height:  $height;
}

.switch input:checked + label:before {
    content: attr(data-on);
    position: absolute;
    left: 0;
    font-size:10px;
    padding-left: $height / 4;
    color: white;
}

.switch label:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: $height - 6px;
    height: $height - 6px;
    background: #fff;
    border-radius: $height / 5;
    //transition: $transition-time;
}

.switch input:checked + label {
    background: $blue;
    //transition: $transition-time;
}

.switch input:checked + label:after {
    //left: calc(100% - 0.1rem);
    //transform: translateX(-100%);
    transform: translateX($width - $height);
}
