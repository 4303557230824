$font-color: #4a494a;
$secondary-color: #e62b4a;
$aqua-color: #fff;
$primary-color: #2e88cd;
$title-color: #777f88;
$content-color: #95989a;
body {
    font-family: "Overpass", sans-serif;
    font-weight: 400;
    color: $font-color;
}
.container {
    max-width: 1170px;
}
p {
    font-size: 16px;
    line-height: 26px;
    color: $font-color;
    margin: 0 0 30px 0;
}
a {
    text-decoration: none;
    outline: 0;
    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        box-shadow: none !important;
    }
}
textarea {
    resize: none;
}
.like-h1 {
    font-size: 40px;
    line-height: 50px;
    color: $font-color;
    font-weight: 300;
    margin: 0 0 30px 0;
    strong {
        font-weight: 700 !important;
    }
}
ul {
    list-style: none;
    padding: 0;
}
section {
    background-color: #fff;
    padding: 20px 0;
}
.btn-custom {
    padding: 12px 45px;
    font-size: 16px;
    line-height: 28px;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0;
    color: #fff;
    background-color: #0267d4;
    &:hover,
    &:focus,
    &:active {
        color: #fff;
    }
}
.section-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    margin: 0 0 15px 0;
    color: $font-color;
}
.img-fix {
    position: relative;
    top: -2px;
}
.provider-clinic-list {
    padding-top: 80px;
    position: relative;
    .map-right-sidebar {
        width: 400px;
        position: fixed;
        right: 0;
        bottom: 0;
        #gmap_canvas {
            width: 100%;
            height: 100vh;
        }
        .filterSec {
            .form-check {
                top: 95px;
                left: 0;
                right: 0;
                bottom: auto;
                margin: 0 auto;
                position: absolute;
                width: calc(100% - 40px);
                padding: 0;
                z-index: 3;
                box-shadow: 0px 10px 15px #255a961a;
                .form-control {
                    padding: 24px 15px 20px 30px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.278' height='15.038' viewBox='0 0 11.278 15.038'%3E%3Cpath id='select-location-icon' d='M7.639,15.038s5.639-5.344,5.639-9.4A5.639,5.639,0,1,0,2,5.639C2,9.694,7.639,15.038,7.639,15.038Zm0-6.579a2.82,2.82,0,1,1,2.82-2.82A2.82,2.82,0,0,1,7.639,8.459Z' transform='translate(-2)' fill='%23999999'/%3E%3C/svg%3E%0A");
                    background-position: 10px center;
                    background-repeat: no-repeat;
                    background-size: auto 16px;
                }
            }
        }
    }
    .global_loder {
        position: absolute;
        height: 68px;
        width: 100px;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }
}
.inPatientPortal.provider-clinic-list {
    padding: 0;
    .listing-left-sec .provider-list-cal .calender-view-sec {
        top: 0;
    }
    .listing-left-sec ul.nav.nav-tabs {
        padding: 15px 0;
    }
    .fitlter-box .form-check select {
        max-width: 200px;
    }
    .map-right-sidebar .filterSec .form-check {
        top: 15px;
    }
}
.listing-left-sec {
    width: calc(100% - 400px);
    min-height: calc(100vh - 140px);
    padding-top: 15px;
    .nav-tabs {
        border: none;
        button:first-child {
            margin-left: 0 !important;
        }
    }
    .btn-clear-filter {
        color: #fff;
        background-color: #2e88cd;
        padding: 9px 15px 0 15px;
        height: 40px;
    }
    .nav-link {
        color: #9d9d9d;
        border: 0;
        margin: 0;
        padding: 10px 0;
        margin: 0 15px;
        position: relative;
        background-color: transparent;
        &:focus,
        &:hover,
        &:active {
            outline: none;
        }
    }
    .nav-item {
        &:first-child {
            .nav-link {
                margin-left: 0;
            }
        }
        &:last-child {
            .nav-link {
                margin-right: 0;
            }
        }
    }
    .nav-link.active {
        color: #333;
        font-weight: 700;
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 3px;
            background-color: #333333;
            left: 0;
            bottom: 0;
        }
    }
    .custom-check-list {
        input[type="checkbox"] {
            display: none !important;
        }
        .form-check {
            position: relative;
            z-index: 0;
            padding: 0;
            margin: 7px 14px 7px 0;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
            input {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 15px;
                right: auto;
                margin: auto 0;
                &:focus {
                    box-shadow: none !important;
                }
            }
            label {
                padding: 8px 15px 4px 15px;
                border: 1px solid #9d9d9d;
                border-radius: 50px;
            }
            input[type="checkbox"] {
                &:checked {
                    + {
                        label {
                            border-color: #2e88cd !important;
                            background-color: rgba(46, 136, 205, 0.1);
                        }
                    }
                }
            }
        }
    }
    .provider-list-cal {
        .provider-list-box {
            li {
                padding: 30px 0;
                border-top: 1px solid #e9e9e9;
                border-bottom: 1px solid #e9e9e9;
                margin-top: -1px;
                &:first-child
                {
                    border-top: none;
                }
            }
            .left-sec {
                width: calc(100% - 380px);
                .pro-sub-txt {
                    font-size: 14px;
                    font-weight: 600;
                }
                .pro-main-txt {
                    font-size: 24px;
                    font-weight: 600;
                }
                .pro-dent-link {
                    a {
                        color: #2e88cd;
                        cursor: pointer;
                    }
                }
                .pro-add {
                    color: #95989a;
                }
                .media {
                    .media-img {
                        .img-box {
                            height: 120px;
                            width: 120px;
                            border-radius: 50%;
                            overflow: hidden;
                            border: 1px solid #d8d8d8;
                            background-color: #fefefe;
                        }
                        .btn-link {
                            color: #2e88cd;
                            display: block;
                            font-size: 14px;
                        }
                    }
                }
            }
            .right-sec {
                width: 360px !important;
                .time-slot-sec {
                    margin-bottom: 15px;
                    span {
                        background-color: #e9e9e9;
                        color: #95989a;
                        display: inline-block;
                        height: 34px;
                        width: 64px;
                        text-align: center;
                        margin: 4px;
                        line-height: 38px;
                        font-size: 13px;
                    }
                    .asigned-slot {
                        background-color: #95989a;
                        color: #fff;
                        cursor: pointer;
                    }
                    .moreTimeBtn {
                        cursor: pointer;
                    }
                }
                .border-btn {
                    border: 1px solid #2e88cd;
                    border-radius: 4px;
                    background-color: rgba(46, 136, 205, 0.1);
                    color: #2e88cd;
                    padding: 8px 15px 4px 15px;
                    margin: 7px 4px;
                    img {
                        position: relative;
                        top: -2px;
                    }
                }
                .info-txt {
                    color: #9d9d9d;
                    font-size: 14px;
                    margin-bottom: 15px;
                }
                .cd-dr-img {
                    padding-right: 20px;
                }
                .cd-dr-img .img-box {
                    height: 64px;
                    width: 64px;
                    overflow: hidden;
                    border: 1px solid #ffffff;
                    border-radius: 50%;
                    margin: 0 -15px 0 0;
                    display: inline-block;
                }
            }
        }
        .calender-view-sec {
            background-color: #fff;
            position: sticky;
            position: -webkit-sticky;
            top: 74px;
            z-index: 2;
            border-bottom: 1px solid #e9e9e9;
            .calender-view {
                position: relative;
                width: 360px;
                .cal-box {
                    width: 64px;
                    margin: 0 4px;
                    text-align: center;
                    span {
                        display: block;
                    }
                    .day-txt {
                        font-weight: 600;
                    }
                    .date-txt {
                        font-size: 14px;
                    }
                }
                .btn-box {
                    width: 12px;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto 0;
                    height: 30px;
                    .btn {
                        background-position: center;
                        background-repeat: no-repeat;
                        padding: 14px 5px;
                    }
                    &:first-child {
                        left: -12px;
                        .btn {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.002' height='13.002' viewBox='0 0 7.002 13.002'%3E%3Cpath id='Path_4540' data-name='Path 4540' d='M6.646,3.646a.5.5,0,0,1,.708,0l6,6a.5.5,0,0,1,0,.708l-6,6a.5.5,0,0,1-.708-.708L12.293,10,6.646,4.354a.5.5,0,0,1,0-.708Z' transform='translate(13.501 16.501) rotate(180)' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
                        }
                    }
                    &:last-child {
                        right: -12px;
                        .btn {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.002' height='13.002' viewBox='0 0 7.002 13.002'%3E%3Cpath id='Path_4539' data-name='Path 4539' d='M6.646,3.646a.5.5,0,0,1,.708,0l6,6a.5.5,0,0,1,0,.708l-6,6a.5.5,0,0,1-.708-.708L12.293,10,6.646,4.354a.5.5,0,0,1,0-.708Z' transform='translate(-6.499 -3.499)' fill-rule='evenodd'/%3E%3C/svg%3E ");
                        }
                    }
                }
            }
        }
    }
    .fitlter-box {
        .form-check {
            padding: 0 0 0 1.5rem;
            &:last-child {
                padding-right: 1.5rem;
            }
            select {
                height: 40px;
                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;
                min-width: 180px;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
                background-repeat: no-repeat;
                background-position: right 0.75rem center;
                background-size: 16px 12px;
            }
            .select-date {
                height: 40px;
                padding: 0.375rem 0.75rem;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
                width: 160px;

                .MuiInput-underline::before,
                .MuiInput-underline::after {
                    display: none;
                }
                .MuiIconButton-root {
                    padding: 0;
                }
            }
        }
    }
    .btn-filter {
        border: 1px solid #ced4da;
        padding: 10px 0.75rem 0 0.75rem;
        border-radius: 0.25rem;
        height: 100%;
        cursor: pointer;
    }
}
.filter-popup {
    outline: none;
    &:focus {
        outline: none;
    }
    .modal-dialog {
        outline: none;
        border: none !important;
        &:focus {
            outline: none;
            border: none !important;
        }
    }
    .modal-header,
    .modal-footer,
    .modal-body {
        padding: 1rem 2rem;
        border: none;
    }
    .more-filter-box {
        .form-check .form-check-input {
            margin-top: 2px;
        }
        .form-check-label {
            padding-left: 7px;
        }
        .form-label {
            font-weight: 600;
            font-size: 18px;
        }
        .text-muted {
            color: #95989a !important;
            font-size: 14px;
        }
    }
    .btn-custom {
        padding: 8px 30px 4px 30px !important;
    }
    .modal-content {
        padding: 15px 7px;
        border: none !important;
    }
    select {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        min-width: 180px;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 16px 12px;
    }
}
input,
select,
texarea,
button {
    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }
}
.form-check-input {
    width: 1em;
    height: 1em;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-top-color: rgba(0, 0, 0, 0.25);
    border-right-color: rgba(0, 0, 0, 0.25);
    border-bottom-color: rgba(0, 0, 0, 0.25);
    border-left-color: rgba(0, 0, 0, 0.25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
}
.form-check-input:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
}
.form-check-input[type="radio"] {
    border-radius: 50%;
}
.form-check .form-check-input {
    float: left;
    margin-top: 2px;
}
.form-check-input[type="checkbox"] {
    border-radius: 0.25em;
}
.loginPopup,
.regPopup,
.book_popup_nw {
    .modal-content {
        border-radius: 0.3rem;
        overflow: hidden;
    }
    .modal-header {
        padding: 15px 30px;
        background: #fafafb;
    }
    .modal-body {
        padding: 30px;
    }
    .formLinks a {
        text-decoration: underline;
        font-size: 14px;
        line-height: 22px;
        cursor: pointer;
    }
    .btn-custom {
        padding: 8px 15px 4px 15px;
    }
    .form-check input {
        padding: 0 !important;
    }
    .MuiInput-underline::before,
    .MuiInput-underline::after {
        display: none;
    }
    .MuiFormControl-root.MuiTextField-root {
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
    }
    .fw-bold {
        font-weight: 600;
    }
    .pincode-input-container {
        margin-bottom: 1rem;
    }
}
.book_popup_nw {
    .list-group-item {
        border-radius: 8px;
        margin: 7px 0;
        border: 1px solid rgba(0, 0, 0, 0.125) !important;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            border: 2px solid #f295a4;
            opacity: 0;
            top: 0;
            left: 0;
            border-radius: 8px;
        }
        &.active {
            background-color: #f9f9f9;
            color: #4a494a;
            border-color: transparent !important;
            &::after {
                opacity: 1;
            }
        }
    }
    .modal-dialog {
        position: absolute;
        left: 0;
        right: 0;
        transform: translateY(-50%) !important;
        top: 50%;
        max-width: 550px !important;
    }
    select {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        min-width: 180px;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 16px 12px;
    }
    .publp_form input.dr-selected:checked + .media {
        border: none;
        background-color: transparent !important;
        margin: 0;
        padding: 0 !important;
    }
    .likeInputBox,
    .MuiFormControl-root {
        border: none !important;
    }
    .MuiInputBase-input {
        padding: 0;
    }
}
.loginPopup,
.listOfPatients {
    .modal-dialog {
        max-width: 400px !important;
        position: absolute;
        left: 0;
        right: 0;
        transform: translateY(-50%) !important;
        top: 50%;
    }
}
.regPopup {
    .modal-dialog {
        max-width: 600px !important;
    }
    .MuiInput-underline::before,
    .MuiInput-underline::after {
        display: none;
    }
    .MuiFormControl-root.MuiTextField-root {
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        min-height: 38px;
    }
    select {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        min-width: 180px;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 16px 12px;
    }
    .modal-footer {
        border-top: none;
        padding-bottom: 30px;
        padding-top: 0;
    }
    .btn-outline-secondary {
        text-transform: uppercase;
        font-weight: 700;
        line-height: 28px;
        padding: 8px 15px 4px 15px;
    }
    .form-control {
        margin-bottom: 1rem;
    }
}
.lastStep {
    .modal-dialog {
        transform: none !important;
        top: 0;
    }
}
.langDropdown .form-control {
    height: 40px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
}
.book_popup_nw {
    .addBtn {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.89' height='10.89' viewBox='0 0 10.89 10.89'%3E%3Cpath id='Path_4523' data-name='Path 4523' d='M9.445,4a.681.681,0,0,1,.681.681V8.764H14.21a.681.681,0,0,1,0,1.361H10.126V14.21a.681.681,0,0,1-1.361,0V10.126H4.681a.681.681,0,0,1,0-1.361H8.764V4.681A.681.681,0,0,1,9.445,4Z' transform='translate(-4 -4)' fill='%23212529'/%3E%3C/svg%3E%0A");
        background-position: left 10px;
        background-repeat: no-repeat;
        padding-left: 20px;
        background-size: 12px;
    }
}
.is-invalid {
    color: #dc3545 !important;
    font-size: 14px;
}
.btn-close {
    background-image: url("data:image/svg+xml,%3Csvg id='Capa_1' enable-background='new 0 0 386.667 386.667' height='512' viewBox='0 0 386.667 386.667' width='512' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m386.667 45.564-45.564-45.564-147.77 147.769-147.769-147.769-45.564 45.564 147.769 147.769-147.769 147.77 45.564 45.564 147.769-147.769 147.769 147.769 45.564-45.564-147.768-147.77z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px;
    background-color: transparent;
    box-shadow: none;
    border: none;
    position: absolute;
    top: 7px;
    right: 7px;
}
.subtxt-box {
    font-size: 18px;
}
.likeInputBox {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    height: 37px;
}
.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .master-header .navbar {
        padding: 5px 0;
    }
    .listing-left-sec {
        width: calc(100% - 260px);
    }
    .provider-clinic-list .map-right-sidebar {
        width: 260px;
    }
    .listing-left-sec .provider-list-cal .calender-view-sec .calender-view {
        width: 312px;
    }
    .listing-left-sec .provider-list-cal .provider-list-box .right-sec {
        width: 100% !important;
        max-width: 312px;
    }
    .listing-left-sec .provider-list-cal .provider-list-box .left-sec {
        width: calc(100% - 322px);
    }
    .listing-left-sec .provider-list-cal .provider-list-box .right-sec .time-slot-sec span {
        font-size: 10px;
        width: 54px;
    }
    .listing-left-sec .provider-list-cal .provider-list-box .left-sec .media .media-img .img-box {
        width: 76px;
        height: 76px;
    }
    .listing-left-sec .provider-list-cal .provider-list-box .left-sec .pro-main-txt {
        font-size: 20px;
    }
    .listing-left-sec .fitlter-box .filter-ops {
        max-width: 260px;
    }
    .listing-left-sec .provider-list-cal .calender-view-sec .calender-view .cal-box .day-txt {
        font-size: 15px;
    }
    .listing-left-sec .provider-list-cal .calender-view-sec .calender-view .cal-box .date-txt {
        font-size: 13px;
    }
    .listing-left-sec .provider-list-cal .provider-list-box.list-of-clinic .left-sec {
        width: calc(100% - 230px);
    }
    .listing-left-sec .provider-list-cal .provider-list-box.list-of-clinic .right-sec {
        max-width: 200px;
    }
    .listing-left-sec .provider-list-cal .calender-view-sec h2 {
        font-size: 28px;
    }
    .listing-left-sec .provider-list-cal .provider-list-box .right-sec .border-btn
    {
        font-size: 14px;
    }
    .listing-left-sec .provider-list-cal .provider-list-box .right-sec .cd-dr-img
    {
        margin-bottom: 5px;
    }
    .listing-left-sec .provider-list-cal .provider-list-box .right-sec .cd-dr-img .img-box
    {
        height: 48px;
        width: 48px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .provider-clinic-list .map-right-sidebar {
        display: none;
    }
    .listing-left-sec {
        width: 100%;
    }
    .listing-left-sec .provider-list-cal .calender-view-sec {
        top: 65px;
        h2 {
            font-size: 28px;
        }
    }
    .listing-left-sec .fitlter-box .filter-ops {
        max-width: 280px;
    }
    .listing-left-sec .provider-list-cal .provider-list-box .right-sec {
        max-width: 340px;
        width: 100%;
    }
    .listing-left-sec .provider-list-cal .provider-list-box .left-sec {
        width: calc(100% - 350px);
    }
    .listing-left-sec .provider-list-cal .provider-list-box .left-sec .media .media-img .img-box {
        width: 76px;
        height: 76px;
    }
    .listing-left-sec .provider-list-cal .calender-view-sec .calender-view {
        width: 340px;
    }
    .listing-left-sec .provider-list-cal .calender-view-sec .calender-view .cal-box .day-txt {
        font-size: 15px;
    }
    .listing-left-sec .provider-list-cal .calender-view-sec .calender-view .cal-box .date-txt {
        font-size: 13px;
    }
    .listing-left-sec .provider-list-cal .provider-list-box .right-sec .time-slot-sec span {
        font-size: 12px;
        width: 60px;
    }
    .listing-left-sec .provider-list-cal .provider-list-box .left-sec {
        .pro-main-txt {
            font-size: 20px;
        }
        .pro-dent-link,
        .pro-add {
            font-size: 15px;
            margin: 0 !important;
        }
    }
    .listing-left-sec .provider-list-cal .provider-list-box.list-of-clinic .left-sec {
        width: calc(100% - 230px);
    }
    .listing-left-sec .provider-list-cal .provider-list-box.list-of-clinic .right-sec {
        max-width: 200px;
    }
    .listing-left-sec .provider-list-cal .provider-list-box .right-sec .border-btn
    {
        font-size: 14px;
    }
    .listing-left-sec .provider-list-cal .provider-list-box .right-sec .cd-dr-img
    {
        margin-bottom: 5px;
    }
    .listing-left-sec .provider-list-cal .provider-list-box .right-sec .cd-dr-img .img-box
    {
        height: 48px;
        width: 48px;
    }
}
@media only screen and (max-width: 767px) {
    .listing-left-sec {
        width: 100%;
        .provider-list-cal .provider-list-box .left-sec .media .media-img .img-box {
            height: 72px;
            width: 72px;
        }
        .fitlter-box {
            justify-content: center;
            .filter-ops {
                width: 100%;
                padding: 0 1rem;
                .select-date {
                    width: 100%;
                }
            }
            .locFilter {
                height: 40px;
                .form-control {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.278' height='15.038' viewBox='0 0 11.278 15.038'%3E%3Cpath id='select-location-icon' d='M7.639,15.038s5.639-5.344,5.639-9.4A5.639,5.639,0,1,0,2,5.639C2,9.694,7.639,15.038,7.639,15.038Zm0-6.579a2.82,2.82,0,1,1,2.82-2.82A2.82,2.82,0,0,1,7.639,8.459Z' transform='translate(-2)' fill='%23757575'/%3E%3C/svg%3E%0A");
                    background-position: calc(100% - 15px) center;
                    background-repeat: no-repeat;
                    background-size: auto 16px;
                }
            }
            .form-btns
            {
                padding: 0 1rem!important;
                width: 50%;
                .btn-filter, .btn-clear-filter
                {
                    width: 100%;
                    text-align: center;
                }
            }
        }
        .provider-list-cal .calender-view-sec .calender-view {
            width: 312px;
            margin: auto;
        }
        .provider-list-cal .calender-view-sec .calender-view .cal-box {
            .day-txt {
                font-size: 14px;
            }
            .date-txt {
                font-size: 12px;
            }
        }
        .provider-list-cal .provider-list-box .left-sec .media .media-img .btn-link {
            font-size: 13px;
        }
        .provider-list-cal .provider-list-box .left-sec {
            width: 100%;
        }
        .provider-list-cal .provider-list-box .right-sec {
            width: 100% !important;
            margin: 15px auto 0 auto;
            justify-content: end;
        }
        .provider-list-cal .calender-view-sec {
            top: 65px;
            h2 {
                font-size: 20px;
                margin-bottom: 30px;
            }
        }
        .provider-list-cal .provider-list-box .right-sec .time-slot-sec span {
            font-size: 10px;
            width: 54px;
        }
        .provider-list-cal .provider-list-box .left-sec {
            .pro-main-txt {
                font-size: 18px;
            }
            .pro-dent-link {
                font-size: 15px;
                margin: 0 !important;
            }
            .pro-add {
                font-size: 15px;
                margin: 0 !important;
            }
        }
    }
    .map-right-sidebar {
        display: none;
    }

    .addMemberNw .modal-dialog {
        transform: none !important;
        top: 0;
        .MuiFormControl-root.MuiTextField-root {
            width: 100%;
        }
    }
    .book_popup_nw {
        .MuiFormControl-root {
            border: 1px solid #ced4da !important;
            width: 100%;           
        }
        .likeInputBox
        {
            padding: 6px 15px 8px 15px;
            border: 1px solid #ced4da !important;
            width: 100%; 
        }
        .MuiInputBase-input {
            padding: 8px 15px;
        }
    }
    .inPatientPortal.provider-clinic-list .fitlter-box .form-check select
    {
        max-width: 100%;
    }
    .listing-left-sec .provider-list-cal .provider-list-box .right-sec .cd-dr-img .img-box
    {
        height: 36px;
        width: 36px;
    }
    .listing-left-sec .provider-list-cal .provider-list-box .right-sec .cd-dr-img
    {
        height: 36px;
        margin: 0 15px 0 0;
    }
    .listing-left-sec .provider-list-cal .provider-list-box .right-sec .border-btn
    {
        margin: 0;
    }
}
@media only screen and (max-width: 359px) {
    .listing-left-sec .provider-list-cal .provider-list-box .right-sec .time-slot-sec span {
        font-size: 10px;
        width: 47px;
    }
    .listing-left-sec .provider-list-cal .calender-view-sec .calender-view {
        width: 280px;
    }
    .listing-left-sec .provider-list-cal .provider-list-box .right-sec {
        max-width: 280px;
    }
    .inPatientPortal.provider-clinic-list .fitlter-box .form-check select
    {
        max-width: none;
    }
}

.answer-p {
    font-size: 14px;
    color: #999;
}