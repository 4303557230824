/* --- Listing page start --- */
.inner-header
{
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,0.08);
    width: 100%;
    position: fixed;
    z-index: 10;
}
.result-list-page-title {
    border-bottom: 1px solid #ededed;
    padding: 0 0 30px 0;
    margin: 0 0 80px 0;
    .shwrt {
        margin:32px 0 0 0;
    }
}
.list-page
{
    position: relative;
    background-color: #F9F9F9;
    min-height: 100vh;
    .map-sec
    {
        margin-top: 0;        
        height: 100vh;
        position: fixed!important;
        max-width: 33vw;
        width: 100%;
        border-left: 1px solid #E6E6E6; 
        top: 0;
        right: 0;
        iframe 
        { 
            width: 100%;
            height: 100%!important;            
        }
    }
}
.list-box-sec
{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 15px #0000000D;
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    padding: 30px;
    margin: 80px 0;
    &:hover
    {
        border-color: $secondary-color;
        cursor: pointer;
    }
    &:last-child
    {
        margin-bottom: 0;
    }
    .cd-dr-img
    {
        margin-top: -72px;
        line-height: 0;
        .img-box
        {   
            height: 80px;
            width: 80px;
            &.lmo {
                background: #f9f9f9;
                text-align: center;
                line-height: 70px;
                font-size: 24px;
                font-weight: 600;
            }
        }       
    }
    .btn-custom
    {
        padding: 10px 45px 10px 20px;
        line-height: 16px;
        position: relative;
        text-transform: capitalize;
        text-align: left;
        &::after
        {
            content: '';
            @include overlay();
            background-image: url(../images/ic_arrow_forward_24px.svg);
            background-position: calc(100% - 20px) center;
            background-repeat: no-repeat;
            background-size: 14px;
        }
    }
    .trusted-txt
    {
        font-size: 16px;
        color: $secondary-color;
        font-weight: 400;
        margin: 0 0 10px 0;
    }
    .p-name
    {
        font: Bold 24px/34px Roboto;
    }
    .p-address
    {
        color: $font-color;
    }
    .ratting-star
    {
        i{
            color:#FF8C00;
			margin: 0 2px 0 0;
			font-size: 14px;
        }
        strong
        {
            color: $font-color;
            font-size: 16px;
            position: relative;
            line-height: 26px;
            margin: 0 0 0 10px;
            top:3px;
        }
    }
    .patients-no
    {
        font-weight: 400;
        color: $secondary-color;
        margin: 0 0 10px 0;
    }
    .img-list-sec
    {
        text-align: right;
        margin: 15px 0 0 0;
        padding: 15px 0 0 0;
        border-top:1px solid #E9E9E9;
        li 
        {
            display: inline-block;
            margin: 0 3px;
            img 
            {
                height: 22px;
                width: auto;
            }
            span 
            {
                font-size: 16px;
                font-weight: 700;
                color: #95989A;
            }
        }
    }
    .distnc {
        font-weight: 700;
        font-size: 14px;
        margin: 10px 0 0 0;
    }
}
.filter-by-pref
{
    max-width: 360px;
    width: 100%;
    margin: 0 auto;
    .filt_lab {
        font-size: 14px;
        text-align: right;
        margin: 0 0 10px 0;
    }
    select 
    {
        padding: 6px 10px;
        height: auto;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-image: url(../images/dropdown-icon.svg);
        background-repeat: no-repeat;
        background-position: calc(100% - 15px) center;
        background-size: 10px;
        cursor: pointer;
        font-size: 14px;
        border: 1px solid #E6E6E6;
    }
}
@media only screen and (min-width: 320px) and (max-width: 767px) 
{
    .list-box-sec
    {
        position: relative;
        margin: 60px 0;
    }
    .list-box-sec .col
    {
        position: static;
    }
    .list-box-sec .cd-dr-img
    {
        margin: 0;
        position: absolute;
        top: 0;
        right: 15px;
    }
    .list-box-sec .cd-dr-img .img-box
    {
        height: 50px;
        width: 50px;
        margin: -25px 0 0 -20px;
    }
    .list-box-sec .trusted-txt
    {
        font-size: 14px;
        margin: 0 0 7px 0;
        img 
        {
            height: 14px;
            width: auto;
        }
    }
    .list-box-sec .p-name
    {
        font-size: 18px;
        line-height: 28px;
        margin: 0 0 7px 0;
    }
    .list-box-sec .ratting-star img
    {
        max-height: 15px;
    }
    .list-box-sec .btn-custom
    {
        width: 100%;
    }
    .list-box-sec .ratting-star strong
    {
        font-size: 14px;
        line-height: 24px;
        top:1px;
    }
    .list-box-sec .patients-no
    {
        margin: 10px 0;
        font-size: 12px;
    }
}
/* --- Listing page end --- */

.seo-list-page
{
    padding: 0 0 60px 0;
    .best-rated-sec
    {
        margin: 50px 0 0 0;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 15px #0000000D;
        border: 1px solid #E6E6E6;
        border-radius: 10px;
        padding:35px 45px; 
        p 
        {
            margin: 0;
            strong 
            {
                margin: 0 0 15px 0;
                display: block;
            }
        }
    }
    .sc-dr-img {
        .img-box {
            height: 60px;
            width: 60px;
        }
    }

    .list-box-sec
    {
        margin: 0 0 50px 0;
        &:hover
        {
            border-color: $secondary-color;
            cursor: inherit;
        }
        .p-name {
            a {
                color: #444;
            }
        }

        .btn-custom {
            padding: 10px 20px 10px 20px;
            &::after {
                display: none;
            }
        }
        .sedlist{
            margin: 0 0 15px 0;  
            &:nth-child(n+3) {
            }
        }
          
    }
    .sc_cl_img {
        border: 1px solid #cccccc;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
    }
    .sedlist {
        a {
            color: #444;
        }
        .d_name {
            font-weight: 600;
        }
        .ddeg {
            font-size: 14px;
            color: #999;
        }
    }

    a.vaplink {
        color: #444;
        font-weight: 500;
        display: block; 
    }    
}

@media (max-width: 767px) {
    .list-page .map-sec {
        display: none;
    }
    .seo-list-page {
        .list-box-sec {
            .btn-custom {
                padding: 10px 20px 10px 20px;
                margin: 20px 0 0 0;
                text-align: center;
            }
            .sedlist {
                margin: 10px 0;
            }
        }
    }
}