.sub-banner
{   
	background-image: url(../images/about-banner.svg);
	&::after
	{
		content: '';
		@include overlay(); 
		height: 100%;
		width: 100%;
		z-index: -1;
		background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(128,128,128,0.7) 100%);
		background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0)), color-stop(100%, rgba(128,128,128,0.7)));
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(128,128,128,0.7) 100%);
		background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(128,128,128,0.7) 100%);
		background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(128,128,128,0.7) 100%);
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(128,128,128,0.7) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#808080', GradientType=0 );
	}
	h1 
	{
		font-size:60px;
		line-height: 60px;
		font-weight: 700;
		color: #fff;
		margin: 75px 0 0 0;
		padding: 0;
	}
}
.team-sec
{
	padding: 60px 0 30px 0;
	hr 
	{
		margin:15px 0 45px 0;
	}
}
.team-box
{
	margin: 0 0 30px 0;
	border: 1px solid #e1e1e1;

	padding: 1px 1px 20px 1px;
	.team-img
	{
		overflow: hidden;
		max-height: 400px;
		height: 100%;
		width: 100%;
		margin: 0 0 15px 0;
	}
	.team-name
	{
		font-size: 26px;
		line-height: 36px;
		font-weight: 600;
		margin: 0 0 3px 0;
	}
	.team-desg
	{
		font-size: 16px;
		line-height: 26px;
		font-weight: 400;
		text-transform: uppercase;
	}
}
.how-it-work-sec
{
	.like-h1
	{
		font-weight: 700;
	}
	hr 
	{
		margin:45px 0;
	}

}
.how-it-work-steps
{
	.screen-img 
	{
		max-width: 240px;
		height: auto;
		width: 100%;
		-webkit-box-shadow: 0 0 4px 0 rgba(0,0,0,.1);
		-moz-box-shadow: 0 0 4px 0 rgba(0,0,0,.1);
		box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.05);
		border-radius: 10px;
		border: 1px solid #e1e1e1;
	}
	.dashboard_content.top-title p 
	{
		color: #4a494a;
		font-weight: 600;
		font-size: 24px;
		line-height: 34px;
		margin-top: 19px;
		margin-bottom: 12.5px;
	}
	.row
	{
		margin-bottom: 45px;
		&:first-child
		{
			margin-bottom: 0;
		}
	}
	.multi-img 
	{
		margin: 0 1%;
	}
	.row:last-child .dashboard_content.top-title p {
		margin-bottom: 30px;
	}

}
@media only screen and (min-width: 992px) and (max-width: 1199px){
	.how-it-work-steps
	{
		.screen-img 
		{
			max-width: 200px;
		}		
		.dashboard_content.top-title p 
		{
			font-size: 22px;
			line-height: 32px;
		}
	}
	
}
@media only screen and (min-width: 768px) and (max-width: 991px) { 
	.how-it-work-steps
	{
		.screen-img 
		{
			max-width: 150px;
		}
		.dashboard_content.top-title p 
		{
			font-size: 18px;
			line-height: 26px;
		}
	}
}
@media only screen and (min-width: 320px) and (max-width: 767px) { 
	.sub-banner h1
	{
		font-size: 40px;
		line-height: 50px;
		margin: 65px 0 0 0;
	}
	.how-it-work-steps
	{
		.like-h1
		{
			margin: 0;
		}
		.screen-img ,  .multi-img
		{
			margin: 15px 0!important;
		}
		.dashboard_content.top-title p
		{
			font-size: 20px;
			line-height: 28px;
			text-align: center;
		}
		.row
		{
			margin-bottom: 30px;
			flex-direction: column-reverse!important;
			&:last-child
			{
				flex-direction: column!important;
			}
		}
	}

}
.no_banner {
	height: 70px;
}


