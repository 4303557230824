/* --- Header CSS Start --- */
.master-header {
    position: absolute;
    z-index: 99;
    width: 100%;
    padding: 0;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;

    .navbar-brand {
        margin: 0 60px 0 0;
        padding: 0;
    }

    .nav-item {
        .nav-link {
            font-weight: 500;
            padding: 3px 15px 0 15px;
            font-size: 16px;
            line-height: 26px;

            img {
                margin: -4px 7px 0 0;
            }

            .tooltip {
                position: relative;
                opacity: 1;
                display: inline-block;
                z-index: 0;
                margin: 0 0 0 15px;

                .tooltip-arrow {
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-color: transparent;
                    border-style: solid;
                    border-width: 5px 5px 5px 0;
                    border-right-color: $secondary-color;
                    margin: auto 0 auto -5px;
                    top: 0;
                    bottom: 0;
                    left: 0;
                }

                .tooltip-inner {
                    background-color: $secondary-color;
                    font-size: 14px;
                    font-weight: 700;
                    color: #fff;
                    line-height: 14px;
                    padding: .35rem .5rem;
                }
            }
        }
    }

    .navbar {
        padding: 15px 0;

        ul.ml-auto {
            .nav-item {
                .nav-link {
                    padding: 10px 15px 0 15px;
                }

                .refer {
                    padding: 6px 15px 0 15px;
                }

                .btn-custom {
                    background-color: #fff;
                    color: $primary-color;
                    font-size: 14px;
                    line-height: 22px;
                    margin: 0px 0 0 15px;
                    padding: 10px 30px;
                    border: none;
                    font-weight: 700 !important;
                }
            }
        }
    }
}

.bg-transperent {
    background-color: transparent;

    .nav-item {
        .nav-link {
            color: #fff;
        }

        img {
            -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
        }
    }

    .logo-w {
        display: block !important;
    }

    .logo-b {
        display: none !important;
    }
}

.bg-white {
    background-color: #fff;
    box-shadow: 0px 10px 15px #255A961A;

    .nav-item {
        .nav-link {
            color: $font-color;
        }

        .btn-custom {
            box-shadow: 0px 10px 15px #255A961A;
            border: 1px solid #707070 !important;
            color: $font-color !important;
        }
    }

    .logo-w {
        display: none !important;
    }

    .logo-b {
        display: block !important;
    }
}

.sticky {
    background-color: #fff;
    position: fixed !important;
    z-index: 99;
    top: 0;
    width: 100%;
    animation: slide-down 1s forwards;
    box-shadow: 0px 10px 15px #255A961A;

    .nav-item {
        .nav-link {
            color: $font-color;
        }

        .btn-custom {
            box-shadow: 0px 10px 15px #255A961A;
            border: 1px solid #707070 !important;
            color: $font-color !important;
        }
    }

    .logo-w {
        display: none !important;
    }

    .logo-b {
        display: block !important;
    }

    @keyframes slide-down {
        0% {
            opacity: 0;
            transform: translateY(-8.25em);
        }

        100% {
            opacity: 0.98;
            transform: translateY(0);
        }
    }
}

.cs_popload {
    margin: 15px 0;
}

.bg-transperent {
    .yes_login {
        background-color: #fff;
        color: #4a4a4a;
        border: 1px solid #4a4a4a;

        &:hover {}
    }

    .yes_login.show>.btn-primary.dropdown-toggle,
    .yes_login.btn-primary.focus,
    .yes_login.btn-primary:focus {
        background-color: #fff;
        color: #4a4a4a;
        border: 1px solid #4a4a4a;
    }
}
.menuBtnSec
{
    padding: 10px 15px;
}
.bg-white {
    .yes_login {
        background-color: #fff;
        color: #4a4a4a;
        border: 1px solid #4a4a4a;

        &:hover {}
    }

    .yes_login.show>.btn-primary.dropdown-toggle,
    .yes_login.btn-primary.focus,
    .yes_login.btn-primary:focus {
        background-color: #fff;
        color: #4a4a4a;
        border: 1px solid #4a4a4a;
    }
}
.confirmPopup
{
    .modal-header
    {
        padding: 15px 30px;
        background: #fafafb;
        border-bottom: 1px solid #e6e6e6;
    }
    .modal-body
    {
        padding: 30px;
        .btns-box
        {
            .btn 
            {
                padding: 8px 30px 4px 30px;
            }
        }
    }
}