.referral-page
{
    background-color: $primary-color;
    height: 100vh;
    padding:120px 0 0 0;
    .info-sec
    {      
        .section-title
        {
            font-weight: 700;
            color: #fff;
            text-transform: uppercase;
            span
            {
                font-weight: 300;
            }
        }
        .how-list
        {
            padding: 0;
            margin: 30px 0;
            li 
            {
                color: #fff;
                margin: 0 0 15px 0;
                &:last-child
                {
                    margin: 0;
                }
                .title-txt
                {
                    text-transform: uppercase;
                    font-size: 20px;
                    line-height: 28px;
                    font-weight: 700;
                }
                .des-txt
                {
                    font-size: 16px;
                    line-height: 24px;
                }
                .media-img 
                {
                    height: 24px;
                    width: 24px;
                    background-color: #fff;
                    color: $primary-color;
                    border-radius: 50%;
                    font-size: 14px;
                    font-weight: 700;
                    text-align: center;
                    line-height: 26px;
                    margin: 0 15px 0 0;
                }
            }
        }
        .bottom-img
        {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            max-width: 280px;
            width: 100%;
            height: auto;
        }
    }
    .referal-input-box 
    {
        box-shadow: 0px 3px 10px #0000000D;
        border: 1px solid #E9E9E9;
        border-radius: 5px;
        background-color:#fff;
        padding: 30px;
        button
        {
            margin: 15px 0 0 0;
        }
        .step-title
        {
            font-size: 20px;
            font-weight: 700;
            line-height: 18px;
            margin: 15px 0;
            color:#4A494A; 
        }
        .title-sec
        {
            .title-txt
            {
                font-size: 28px;
                font-weight: 700;
                line-height: 36px;
                margin: 0 0 7px 0;
                color:#4A494A;
            }
            p 
            {
                margin: 7px 0;
            }
        }
        .input-sec
        {
            input 
            {
                width: 100%;               
                background-color: #fff;
                padding: 12px 15px;
                height: auto; 
                border: 1px solid #E9E9E9;
                border-radius: 3px;
                margin: 7px 0;
            }
        }
    }

}